/* istanbul ignore file */
import * as Sentry from 'isomorphic-sentry';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    ignoreErrors: [
      /Blocked a frame with origin/,
      /LCM/,
      /Script error/,
      /Failed to register a ServiceWorker/,
      /sw.js/,
      /NetworkError/,
      'document.getElementsByClassName.ToString is not a function',
      /Script load failed/,
      /International roaming is currently off/,
      /NotAllowedError/,
      '<unknown>',
      'undefined',
      'Non-Error promise rejection captured with value',
    ],
    blacklistUrls: [
      /gtm-msr\.appspot\.com/,
      /a\.adroll\.com/,
      /trkcall\.com/,
      /script\.hotjar\.com/,
      /formobile\.com\.au/,
      /script\.hotjar\.com/,
      /maps\.googleapis\.com/,
      /google-analytics\.com/,
    ],
    allowUrls: [
      /^https?:\/\/localhost:\d+\/?$/,
      /^https?:\/\/([a-zA-Z-_0-9]*\.)*(review|test)\.strangeanimals\.com\.au\/?$/,
      /^https?:\/\/((careers|training|www)\.)?(amanaliving|solutionsbyamanaliving)\.com\.au\/?$/
    ],
  });

  process.on('unhandledRejection', (err) => {
    Sentry.captureException({ message: 'error coming from _app', error: err });
  });

  process.on('uncaughtException', (err) => {
    Sentry.captureException({ message: 'error coming from _app', error: err });
  });
}

export default Sentry;
