import React from 'react';

import { EmailButton, PhoneButton } from '../../index';

import PhoneIcon from '../../../images/icons/phone.svg';

import styles from './styles.module.scss';

const StickyMobile = () => (
  <div className={styles.stickyMobile}>
    <PhoneButton className={styles.button}>
      <PhoneIcon />
      Call us
    </PhoneButton>
    <EmailButton text="Email us" className={styles.button} />
  </div>
);

export default StickyMobile;
