import React from 'react';

import { useSiteQuery } from '../../../../hooks';
import { renderCategoryLink } from '../../../../lib/ContentRenderer';

import query from './topMenu.graphql';

const TopMenu = () => {
  const [{ data }] = useSiteQuery<TopMenuQuery>({ query });
  const menus = data?.topNavigation;

  if (menus) {
    return <>{menus.map((m) => m?.linkField?.[0] && renderCategoryLink(m as TopNavigationCategory))}</>;
  }
  return null;
};

export default TopMenu;
