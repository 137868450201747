import React from 'react';
import { useRouter } from 'next/router';

import { useSiteQuery } from '../../../../hooks';
import { renderMainMenu } from '../../../../lib/ContentRenderer';

import query from './mainMenu.graphql';
import {urlFor} from "../../../../lib/Link";

interface Props {
  menuPosition: string;
}

const MainMenu = ({ menuPosition }: Props) => {
  const [{ data }] = useSiteQuery<MainMenuQuery>({ query });
  const menus = data?.mainMenu as MainMenuCategory[];
  const router = useRouter();

  if (menus) {
    const currentPath = router?.asPath ?? '/';

    let menuPathMatches = menus.filter((menuItem: MainMenuCategory) => {
      const linkUrl = urlFor(menuItem.linkField[0]);

      return currentPath.indexOf(linkUrl) === 0;
    });

    menuPathMatches.sort((a: MainMenuCategory,b: MainMenuCategory) => {
      const aPath = urlFor(a.linkField[0]).split('/');
      const bPath = urlFor(b.linkField[0]).split('/');

      return aPath.length - bPath.length;
    });

    const closestMatch = menuPathMatches.pop();

    return <>{menus.map((menuItem: MainMenuCategory) => {
      let isActive;

      if (closestMatch && closestMatch === menuItem) {
        isActive = true;
      } else if (closestMatch && closestMatch !== menuItem) {
        isActive = false;
      } else {
        isActive = currentPath?.indexOf(urlFor(menuItem.linkField[0])) === 0;
      }

      return menuItem?.linkField?.[0] && renderMainMenu(menuItem, menuPosition, isActive);
    })}</>;
  }

  return null;
};

export default MainMenu;
