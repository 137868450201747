import React from 'react';

import { Button, PhoneLink } from '../../../index';
import TopNavigation from '../TopNavigation';

import styles from './styles.module.scss';

const DesktopMenu = () => (
  <div className={styles.desktopMenu}>
    <nav className={styles.topLink} aria-label="Top Links">
      <TopNavigation />
      <div className={styles.topRight}>
        <PhoneLink text="" type="buttonTransparent" className={styles.phoneLink} />
        <Button href="/contact-us" className={styles.contact}>
          Contact us
        </Button>
      </div>
    </nav>
  </div>
);

export default DesktopMenu;
