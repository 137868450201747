export const SitemapHelper = {
    site: 'amanaliving',
    getSitemapIndex: () => {
        return SitemapHelper.query(`
            query SitemapIndexQuery {
              seomatic(site: "${SitemapHelper.site}") {
                sitemapIndexes(site: "${SitemapHelper.site}") {
                  contents
                }
              }
        }`).then((resp) => resp?.seomatic?.sitemapIndexes?.[0]?.contents);
    },
    getSitemapFile: (filename: string) => {
        filename = filename.replace(/^\//,'');

        return SitemapHelper.query(`
            query SitemapQuery {
              seomatic(site: "${SitemapHelper.site}") {
                sitemaps(site: "${SitemapHelper.site}", filename: "${filename}") {
                  filename
                  contents
                }
                sitemapIndexes(site: "${SitemapHelper.site}") {
                  contents
                  filename
                }
              }
        }`).then((resp) => {
            // sitemaps somehow contains an element of null if our filename isnt found
            let sitemap = resp?.seomatic?.sitemaps?.find((sitemap: any) => sitemap?.filename === filename) || null;

            if (!sitemap) {
                // We didnt find our file in the sitemap array.. try the index
                sitemap = resp?.seomatic?.sitemapIndexes?.find((sitemap: any) => sitemap?.filename === filename) || null;
            }

            return sitemap?.contents;
        });
    },
    getSitemapStyle: () => {
        return SitemapHelper.query(`
            query SitemapStyleQuery {
              seomatic(site: "${SitemapHelper.site}") {
                sitemapStyles {
                  contents
                }
              }
        }`).then((resp) => resp?.seomatic?.sitemapStyles?.contents);
    },

    query: (rawQuery: string = '{ping}') => {
        let origin = 'server';
        if (typeof window !== 'undefined' && window !== null) {
            origin = window?.location?.host || origin;
        }

        return fetch(`${process.env.NEXT_NGINX_HOST}/graphql`, {
            method: 'POST',
            body: JSON.stringify({
                query: rawQuery
            }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${process.env.GRAPHQL_TOKEN}`,
                'origin': origin
            },
        }).then((resp: Response) => {
            if (!resp.ok) {
                throw new Error('An unknown error occurred');
            }

            return resp.json();
        }).then((json: any) => {
            return json?.data ?? {};
        })
    }
};
