import React from 'react';
import cc from 'classcat';

import SearchIcon from '../../../../images/icons/search.svg';

import styles from './styles.module.scss';

interface Props {
  skinny?: boolean;
  onClick: () => void;
}

const SearchButton = ({ skinny, onClick }: Props) => (
  <button
    onClick={onClick}
    className={cc({
      [styles.search]: true,
      [styles.skinny]: skinny,
    })}
    aria-label="Search"
  >
    <SearchIcon />
  </button>
);

export default SearchButton;
