import React from 'react';

import { Button, PhoneLink } from '../../../index';

import SearchButton from '../SearchButton';

import styles from './styles.module.scss';

interface Props {
  isJobPage: boolean;
  searchClick: () => void;
}

const DesktopSkinnyMenu = ({ isJobPage, searchClick }: Props) => (
  <div className={styles.skinnyMenu}>
    <nav className={styles.skinnyMenuNav} aria-label="Skinny Menu">
      {!isJobPage && <SearchButton skinny onClick={searchClick} />}
      <div>
        <PhoneLink text="" className={styles.phoneLink} />
        <Button href="/contact-us" className={styles.contact}>
          Contact us
        </Button>
      </div>
    </nav>
  </div>
);

export default DesktopSkinnyMenu;
