import React, { useEffect, useRef, useState } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { useToggle, useWindowScroll } from 'react-use';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useRouter } from 'next/router';

import { Button, PhoneLink, SearchInput } from '../../index';
import { useStore } from '../../../hooks';
import isJobPage from '../../../lib/isJobPage';

import CloseIcon from '../../../images/icons/close.svg';
import PhoneIcon from '../../../images/icons/phone.svg';

import DesktopMenu from './DesktopMenu';
import DesktopSkinnyMenu from './DesktopSkinnyMenu';
import DesktopSubMenu from './DesktopSubMenu';
import MainMenu from './MainMenu';
import SearchButton from './SearchButton';
import TopNavigation from './TopNavigation';

import styles from './styles.module.scss';

interface Props {
  isHubPage: boolean;
}

const Header = ({ isHubPage }: Props) => {
  const { siteName, fullLogo, invertedLogo, careersLink } = useStore('globals');
  const router = useRouter();

  const [mobileMenuOpen, toggleMobileMenu] = useToggle(false);
  const [searchActive, setSearchActive] = useState(false);
  const mobileMenuRef = useRef<HTMLDivElement>(null);
  const [scrolled, setScrolled] = useState(false);
  const { y } = useWindowScroll();

  const jobPageActive = router?.asPath === '/jobs' || isJobPage;

  const offset = 150;

  useEffect(() => {
    // istanbul ignore else
    if (mobileMenuRef.current) {
      if (mobileMenuOpen) {
        disableBodyScroll(mobileMenuRef.current);
      } else {
        enableBodyScroll(mobileMenuRef.current);
      }
    }
  }, [mobileMenuOpen]);

  useEffect(() => {
    const scrollY = window.scrollY > offset;
    setScrolled(scrollY);
  }, [y]);

  return (
    <header
      className={cc({
        [styles.header]: true,
        [styles.mobileMenuOpen]: mobileMenuOpen,
      })}
    >
      <div
        className={cc({
          [styles.searchOverlay]: true,
          [styles.searchActive]: searchActive,
        })}
      >
        <div className={styles.searchContainer}>
          <SearchInput active={searchActive} />
          <button className={styles.closeButton} onClick={() => setSearchActive(false)}>
            <CloseIcon width="24" height="24" />
          </button>
        </div>
      </div>

      { !jobPageActive &&
          <div className={cc({[styles.skinny]: true, [styles.scrolled]: scrolled})}>
            <div className={'container-skinny'}>
              <div className={'container-skinny-top'}>
                <a title="Click to return home" href={jobPageActive ? careersLink : '/'}
                    className={styles.invertedLogo}>
                  <img src={invertedLogo} alt={siteName}/>
                </a>
                <DesktopSkinnyMenu isJobPage={jobPageActive} searchClick={() => setSearchActive(true)}/>
              </div>

              <div className={'container-skinny-bottom'}>
                <MainMenu menuPosition="skinnyMenu"/>
              </div>
            </div>
          </div>
      }

      <div ref={mobileMenuRef} className={styles.mobileOverlay}>
        <div className={styles.menu}>
          <MainMenu menuPosition="header" />
          <TopNavigation />
          <div>
            <PhoneLink type="buttonWhite" className={styles.callUs}>
              <PhoneIcon />
              Call us
            </PhoneLink>
            <Button href="/contact-us" type="whiteBorder" className={styles.contactUs}>
              Contact us
            </Button>
          </div>
        </div>
      </div>

      {/* DESKTOP MENU - TOP AND BOTTOM MENU */}
      <div className={'grid-container-desktop'}>
        <a href={jobPageActive ? careersLink : '/'} className={'col-span-2 row-span-2 logo-padding'}>
          <img src={fullLogo} alt={siteName} className={'logo-width'} />
        </a>

        <div className={cc({ container: true, [styles.headerContainer]: true })}>
          <DesktopMenu />

          <div className={styles.mobileMenu}>
            {isHubPage && (
                <PhoneLink type="button" className={styles.phoneLink}>
                  <PhoneIcon />
                  Call us
                </PhoneLink>
            )}

            {!jobPageActive && <SearchButton onClick={() => setSearchActive(true)} />}
            <button
                className={cc(['hamburger', 'hamburger--3dy', { 'is-active': mobileMenuOpen }])}
                onClick={toggleMobileMenu}
                type="button"
                aria-label="Open menu"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>

        <DesktopSubMenu isJobPage={jobPageActive} searchClick={() => setSearchActive(true)} />
      </div>
    </header>
  );
};

export default observer(Header);
