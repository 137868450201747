import { cacheExchange, dedupExchange, fetchExchange } from "@urql/core";
import { NextUrqlClientConfig, SSRExchange } from "next-urql";
import {getServerAddress} from "../lib/isBrowser";
let graphqlConfig: NextUrqlClientConfig;

if (process.env.MOCK_GRAPHQL === "true") {
  const { executeExchange } = require("@urql/exchange-execute");
  const { getMockedSchema } = require("../test/graphql/urqlClient");
  const schema = getMockedSchema();
  graphqlConfig = (ssrExchange: SSRExchange) => ({
    url: `${getServerAddress()}/graphql`,
    exchanges:
      process.env.NODE_ENV === "production"
        ? [
            dedupExchange,
            cacheExchange,
            ssrExchange,
            executeExchange({ schema }),
          ]
        : [
            require("@urql/devtools").devtoolsExchange,
            dedupExchange,
            cacheExchange,
            ssrExchange,
            executeExchange({ schema }),
          ],
  });
} else {
  graphqlConfig = (ssrExchange: SSRExchange) => ({
    url: `${getServerAddress()}/graphql`,
    fetchOptions: {
      headers: {
          Authorization: `Bearer ${process.env.NEXT_GRAPHQL_TOKEN?.replace(/"/g, "")}`,
          Origin: typeof window !== 'undefined' ? window.location.origin : 'server',
      }
    },
    exchanges:
      process.env.NODE_ENV === "production"
        ? [dedupExchange, cacheExchange, ssrExchange, fetchExchange]
        : [
            require("@urql/devtools").devtoolsExchange,
            dedupExchange,
            cacheExchange,
            ssrExchange,
            fetchExchange,
          ],
  });
}

export default graphqlConfig;
