import React, { useEffect, useState } from 'react';
import cc from 'classcat';
import { useWindowScroll } from 'react-use';

import { Modal } from '../index';
import Button from '../buttons/Button';
import EnquireForm from '../forms/EnquireForm';
import { useIsMobile, useStore } from '../../hooks';

import styles from './styles.module.scss';

const SolutionsHeader = () => {
  const { fullLogo } = useStore('globals');
  const [isScrolled, setScrolled] = useState(false);
  const [isMobile] = useIsMobile(1024);
  const [enquireModal, setEnquireModal] = useState(false);
  const [phoneSelected, setPhoneSelected] = useState(false);

  const { y } = useWindowScroll();

  const offset = 150;

  const handleClick = (isPhone: boolean) => {
    setEnquireModal(true);
    setPhoneSelected(isPhone);
  };

  useEffect(() => {
    setScrolled(y > offset && !isMobile);
  }, [y, isMobile]);

  return (
    <header className={cc({ [styles.header]: true, [styles.scrolled]: isScrolled })}>
      <div className={styles.left}>
        <img src={fullLogo} alt="Solutions Logo" />
      </div>
      <div className={styles.right}>
        <h3>To Learn More</h3>
        <Button type="orangeBorder" roundCorners onClick={() => handleClick(true)}>
          Request a call back
        </Button>
        <Button type="solidOrange" roundCorners onClick={() => handleClick(false)}>
          Send online enquiry
        </Button>
      </div>
      <Modal isOpen={enquireModal} onRequestClose={() => setEnquireModal(false)} solutionsModal>
        <EnquireForm handleClose={() => setEnquireModal(false)} phoneSelected={phoneSelected} />
      </Modal>
    </header>
  );
};

export default SolutionsHeader;
