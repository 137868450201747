import { flow, observable } from 'mobx';
import { Severity, captureMessage, setExtras } from 'isomorphic-sentry';
import { BaseStore, getOrCreateStore } from 'next-mobx-wrapper';
import { Client } from 'urql';

import globalsQuery from './query.graphql';
import globalsQueryPageUp from './jobs-query.graphql';

class Globals extends BaseStore {
  @observable site = '';
  @observable siteName = 'Amana Living';
  @observable phone = '';
  @observable fullLogo = '';
  @observable invertedLogo = '';

  @observable twitterLink = '';
  @observable instagramLink = '';
  @observable facebookLink = '';
  @observable linkedinLink = '';
  @observable youtubeLink = '';

  @observable mpir = 4.1;

  @observable careersLink = 'https://careers.amanaliving.com.au';
  @observable jobsListingUrl = 'http://jobs.amanaliving.com.au/cw/en/listing';
  @observable jobsListingMobileUrl = 'http://jobs.amanaliving.com.au/mob/cw/en/listing';

  @observable heroText = 'Get in touch with Amana Living’s Customer Care Team who can help with what you need.';
  @observable heroMobileText = 'Contact our Customer Care Team';

  @observable showAnniversary = false;

  @observable freeform?: FreeformFormInterface[];

  init = flow(function* (this: Globals, urqlClient: Client, site: string, url: string) {
    this.site = site;

    if (site === 'training') {
      this.heroText = 'Get in touch with the Amana Living Training Team, who can help with what you need.';
      this.heroMobileText = 'Contact our Training Team';
    }

    const isPageUpTemplate = site === 'careers' && url === '/jobs';

    let results;
    try {
      results = yield urqlClient
        .query<GlobalsQuery>(isPageUpTemplate ? globalsQueryPageUp : globalsQuery, { site: site })
        .toPromise();
    } catch (error) {
      setExtras({ error });
      captureMessage(`Globals query failed`, Severity.Warning);
    }

    if (results?.data?.globalSet) {
      const { globalSet } = results.data;
      this.phone = globalSet?.phone;
      this.fullLogo = globalSet?.siteLogos?.[0]?.fullLogo?.[0]?.url;
      this.invertedLogo = globalSet?.siteLogos?.[0]?.invertedLogo?.[0]?.url;

      this.twitterLink = globalSet?.twitterLink;
      this.instagramLink = globalSet?.instagramLink;
      this.facebookLink = globalSet?.facebookLink;
      this.linkedinLink = globalSet?.linkedinLink;
      this.youtubeLink = globalSet?.youtubeLink;
      this.mpir = globalSet?.mpir;
      this.showAnniversary = globalSet?.toggle;
      this.freeform = isPageUpTemplate ? null : results.data.freeform.forms;
    } else {
      setExtras({ results });
      captureMessage('globals was empty', Severity.Warning);
    }
  });
}

export const getGlobals = getOrCreateStore('globals', Globals);
export default Globals;
