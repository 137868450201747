import * as React from 'react';
import { configure } from 'mobx';
import { Provider, useStaticRendering } from 'mobx-react';
import { HelmetProvider } from 'react-helmet-async';
import HTMLReactParser from 'html-react-parser';
import { withMobx } from 'next-mobx-wrapper';
import { withServerContext } from 'next-server-context';
import { withUrqlClient } from 'next-urql';
import App, { AppInitialProps } from 'next/app';
import Head from 'next/head';

import { AlertBar } from '../components';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import StickyMobile from '../components/layout/StickyMobile';
import SolutionsHeader from '../components/SolutionsHeader';
import graphqlConfig from '../config/graphqlConfig';
import Sentry from '../lib/Sentry';
import Sites from '../lib/Sites';
import * as getStores from '../stores';

import Error from './_error';
import seoQuery from './seo.graphql';

import '../styles/main.scss';
import {SitemapHelper} from "../lib/SitemapHelper";

useStaticRendering(typeof window === 'undefined'); // eslint-disable-line react-hooks/rules-of-hooks
configure({ enforceActions: 'observed' });

type Props = {
  currentPath: string;
  isHome: boolean;
  isHubPage: boolean;
  store: Stores;
  site: string;
  seo?: SEOMaticDataWithScripts;
  hideNavigation: boolean;
} & AppInitialProps;

class Main extends App<Props> {
  static async getInitialProps(ctx: AppContextWithStores) {
    let site: Nullable<string> = ctx.ctx.req?.headers['x-site']?.toString() || 'amanaliving';
    if (site.includes('solutions')) {
      site = 'solutions';
    } else if (!['amanaliving', 'training', 'careers', 'solutions'].includes(site)) {
      site = 'amanaliving';
    }

    SitemapHelper.site = site;

    if (typeof ctx.ctx.store !== 'undefined') {
      await ctx.ctx.store.globals.init(ctx.ctx.urqlClient!, site,  ctx.ctx.req?.url || '');
    }

    const [initialProps, pageOptions] = await Promise.all<AppInitialProps, PageOptions>([
      App.getInitialProps(ctx),
      new Promise(async (resolve) => {
        let uri = ctx.router.asPath.replace(/\?.*/, ''); // Strip query string
        let isPaginated = false;

        // strip leading/trailing slashes
        let entryUri = uri.replace(/(^\/+)|(\/+$)/g, '');

        // Handle SEO for paginated routes
        if (ctx.router.route.match(/\[page\]$/)) {
          isPaginated = true;
          const routeParts = ctx.router.route.split('/');
          if (routeParts.length >= 2) {
            uri = `${routeParts[1]}-page`;
          }
        }

        if (uri === '/courses' && ctx.router.query.category) {
          uri = `${uri}?category=${ctx.router.query.category}`;
        }

        try {
          const result = await ctx.ctx.urqlClient
            .query<SeoQuery>(seoQuery, {
              uri,
              siteSingular: site,
              siteId: Sites[site || ''] || Sites['amanaliving'],
              entryUri: entryUri
            })
            .toPromise();

          if (result.data?.retour?.redirectDestUrl) {
            const requestHost = ctx?.ctx?.req?.headers?.['host'];
            const regex = new RegExp('^(http(s)?):\\/\\/([^\\/]+)(\\/)?([^\\n?#]*)(.*)$');
            const matches = result.data.retour.redirectDestUrl.match(regex);
            const redirectHost = matches?.[3];
            const redirectPath = matches?.[5];

            // if our request host/path does not match the redirect host/path, perform redirect
            // this is in place because retour is erroneously returning redirects for primary site that are defined for subsites only
            if (!(requestHost === redirectHost && redirectPath === entryUri)) {
              ctx.ctx
                .res!.writeHead(result.data.retour.redirectHttpCode || 302, {
                  Location: result.data.retour.redirectDestUrl,
                })
                .end();
            }
          }

          const pageOptions: PageOptions = {
            seoMatic: result.data?.seomatic || {
              metaTitleContainer: '',
              metaTagContainer: '',
              metaLinkContainer: '',
              metaScriptContainer: '',
              metaJsonLdContainer: '',
              metaSiteVarsContainer: '',
              headScripts: undefined,
              bodyScripts: undefined,
            },
            hideNavigation: result.data?.entry?.hideWebsiteNavigation ?? false,
          };

          try {
            if (isPaginated) {
              const page = Array.isArray(ctx.router.query.page) ? ctx.router.query.page[0] : ctx.router.query.page;
              if (page) {
                pageOptions.seoMatic.metaTitleContainer = pageOptions.seoMatic.metaTitleContainer.replace(/\[page\]/g, page);
                pageOptions.seoMatic.metaTagContainer = pageOptions.seoMatic.metaTagContainer.replace(/\[page\]/g, page);
              }
            }

            // Ignore meta titles and tags for search directory pages
            if (ctx.router.route.match(/^\/cars\//)) {
              pageOptions.seoMatic.metaTitleContainer = '';
              pageOptions.seoMatic.metaTagContainer = '';
            }

            if (pageOptions.seoMatic.metaScriptContainer) {
              const scriptObject: SEOMaticMetaScriptObject = JSON.parse(pageOptions.seoMatic.metaScriptContainer);
              pageOptions.seoMatic.headScripts = scriptObject.script;
              pageOptions.seoMatic.bodyScripts = scriptObject.bodyScript;
            }

            ctx.ctx.store.globals.siteName = JSON.parse(pageOptions.seoMatic.metaSiteVarsContainer).siteName || 'Amana Living';
          } catch (e) {
            pageOptions.seoMatic.headScripts = pageOptions.seoMatic.metaScriptContainer; // Fallback for SEOMatic < 3.2.39
          }

          // Remove SEOmatic scripts if this is a Lighthouse audit.
          if (
            (ctx.ctx.req ? ctx.ctx.req.headers['user-agent'] || '' : navigator.userAgent).includes('Chrome-Lighthouse')
          ) {
            pageOptions.seoMatic.headScripts = '';
            pageOptions.seoMatic.bodyScripts = '';
          }

          resolve(pageOptions);
        } catch (e) {
          resolve(pageOptions);
        }
      }),
    ]);

    const hubPages = [
      '/',
      '/home-care',
      '/retirement-villages',
      '/nursing-homes',
      '/other-care-services',
      '/contact-us',
    ];

    return {
      currentPath: ctx.router.asPath,
      isHome: ctx.router.route === '/',
      isHubPage: hubPages.includes(ctx.router.asPath),
      store: ctx.ctx.store,
      site,
      seo: pageOptions.seoMatic,
      hideNavigation: pageOptions.hideNavigation,
      ...initialProps,
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, store, isHome, isHubPage, seo, hideNavigation, site, currentPath } = this.props;

    const renderNavigation = () => {
      if (hideNavigation) {
        return null;
      }

      return !currentPath.startsWith('/nursing-homes/application') &&
      (site === 'solutions' ? <SolutionsHeader /> : <Header isHubPage={isHubPage} />);
    }

    return (
      <Provider {...store}>
        <HelmetProvider>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"
            />
            {seo &&
              HTMLReactParser(
                seo.metaTitleContainer +
                  seo.metaTagContainer +
                  seo.metaLinkContainer +
                  seo.metaJsonLdContainer +
                  seo.headScripts,
              )}
          </Head>
          {seo && seo.bodyScripts && HTMLReactParser(seo.bodyScripts)}

          <AlertBar active={isHome} />
          {renderNavigation()}
          <main>
            {pageProps.error && <Error statusCode={pageProps.error.code} />}
            {pageProps.statusCode === 404 ? <Error statusCode={404} /> : <Component {...pageProps} />}
          </main>
          {site !== 'solutions' && !currentPath.startsWith('/nursing-homes/application') && (
            <Footer isHubPage={isHubPage} />
          )}
          {site !== 'solutions' && !currentPath.startsWith('/nursing-homes/application') && !isHubPage && (
            <StickyMobile />
          )}
        </HelmetProvider>
      </Provider>
    );
  }
}

export default withServerContext(withUrqlClient(graphqlConfig, { ssr: true })(withMobx(getStores)(Main)));
